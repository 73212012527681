import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";
import ReactMarkdown from "react-markdown";
import Helmet from "react-helmet";

import Layout from "../components/Layout";
import ContactSection from "../components/ContactSection";

class ExpertisePageContent extends React.Component {
  render() {
    const content = this.props.page.frontmatter;
    
    return (
      <div>
        <div className="expertise-hero">
          <img src="/img/expertise-hero-cover.svg" alt="expertise cover"/>
        </div>
  
        <div className="content-wrapper small arrow-list">
          <div className="title-3 text-medium bold-weight section-title left">
            <ReactMarkdown children={content.heroTitle}/>
          </div>

          <div className="flex-row justc-space-b alitems-center
                          m-flex-column">
            <div className="two-third-width m-full-width m-mgb-m">
              <div className="flex-row justc-start alitems-center mgb-s">
                <div className="expertise-icon mgr-s">
                  <img src={content.expertiseSectionOne.icon} alt='icon' />
                </div>
                <ReactMarkdown children={content.expertiseSectionOne.title} className="title-4 text-light bold-weight"/>
              </div>
              <ReactMarkdown children={content.expertiseSectionOne.description} className="paragraph-spacing"/>
            </div>
            <div className="one-third-width card with-image light
                            m-half-width s-three-quarter-width">
              <div className="card-image mgb-s "
                   style={{backgroundImage: 'url(' + content.expertiseSectionCard.image + ')'}}>
              </div>
              <ReactMarkdown children={content.expertiseSectionCard.title} className="title-5 medium-weight mgb-xs"/>
              <ReactMarkdown children={content.expertiseSectionCard.description}/>
            </div>
          </div>

          <div className="flex-row justc-space-b mgt-l
                          s-flex-column">
            <div className="half-width s-full-width">
              <div className="flex-row justc-start alitems-center mgb-s">
                <div className="expertise-icon mgr-s">
                  <img src={content.expertiseSectionTwo.icon} alt='icon' />
                </div>
                <ReactMarkdown children={content.expertiseSectionTwo.title} className="title-4 text-light bold-weight"/>
              </div>
              <ReactMarkdown children={content.expertiseSectionTwo.description} className="paragraph-spacing"/>
            </div>
            <div className="half-width s-full-width s-mgt-l">
            <div className="flex-row justc-start alitems-center mgb-s">
                <div className="expertise-icon mgr-s">
                  <img src={content.expertiseSectionThree.icon} alt='icon' />
                </div>
                <ReactMarkdown children={content.expertiseSectionThree.title} className="title-4 text-light bold-weight"/>
              </div>
              <ReactMarkdown children={content.expertiseSectionThree.description} className="paragraph-spacing"/>
            </div>
          </div>


          <div className="mgt-l mgb-xl">
            <div className="flex-row justc-start alitems-center mgb-s">
              <div className="expertise-icon mgr-s">
                <img src={content.expertiseSectionFour.icon} alt='icon' />
              </div>
              <ReactMarkdown children={content.expertiseSectionFour.title} className="title-4 text-light bold-weight"/>
            </div>
            <div className="flex-row justc-space-b
                            s-flex-column">
              <div className="half-width s-full-width">
                <ReactMarkdown children={content.expertiseSectionFour.descriptionLeft}/>
              </div>
              <div className="half-width s-full-width s-mgt-s">
                <ReactMarkdown children={content.expertiseSectionFour.descriptionRight}/>
              </div>
            </div>
          </div>

        </div>
      </div>
    );
  }
};


export const ExpertisePageTemplate = ({ page }) => {
  return (
    <div>
      <ExpertisePageContent page={page}/>
      <ContactSection/>
    </div>
  )
}

const ExpertisePage = ({ data }) => {
  const { markdownRemark: page, footerData, navbarData } = data;
  const {
    frontmatter: {
      seo: { title: seoTitle, description: seoDescription, browserTitle },
    },
  } = page; 

  return (
    <Layout footerData={footerData} navbarData={navbarData}>
      <Helmet>
        <meta name="title" content={seoTitle} />
        <meta name="description" content={seoDescription} />
        <title>{browserTitle}</title>
      </Helmet>
      <ExpertisePageTemplate page={page} />
    </Layout>
  );
};

ExpertisePage.propTypes = {
  data: PropTypes.object.isRequired,
};

export default ExpertisePage;

export const expertisePageQuery = graphql`
  query ExpertisePage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        heroTitle
        expertiseSectionOne {
          title
          icon
          description
        }
        expertiseSectionCard {
          title
          image
          description
        }
        expertiseSectionTwo {
          title
          icon
          description
        }
        expertiseSectionThree {
          title
          icon
          description
        }
        expertiseSectionFour {
          title
          icon
          descriptionLeft
          descriptionRight
        }
        seo {
          browserTitle
          title
          description
        }
      }
    }
    ...LayoutFragment
  }
`;
