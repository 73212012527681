import React from "react";
import { Link } from "gatsby";

class ContactSection extends React.Component {
  render() {
    return (
      <div className="section gradient-section">
        <div className="content-wrapper small">
          <h3 className="title-3 section-title center white-text">Contact</h3>
          <div className="flex-row justc-space-b alitems-stretch
                          s-flex-column s-alitems-center s-mgt-m">
            <Link className="contact-card home" 
                  to="/contact/"
                  state={{ contactType: "commercial-selected" }}>
              <img src="/img/icons/commercial-icon.svg" alt="Suitcase icon"/>
              Contacter le service commercial
            </Link>

            <Link className="contact-card home" 
                  to="/contact/"
                  state={{ contactType: "assistance-selected" }}>
              <img src="/img/icons/assistance-icon.svg" alt="Assistance icon"/>
                Contacter le support client Electre
            </Link>

            <Link className="contact-card home" 
                  to="/contact/"
                  state={{ contactType: "editeurs-selected" }}>
              <img src="/img/icons/editeurs-icon.svg" alt="Books icon"/>
              Contacter le service référencement
            </Link>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactSection;